html, body, header, nav, h1, h2,
p, ul, li, div, form, label, dl, dt, dd,
input, span {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: inherit;
  color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
  box-sizing: inherit;
  background: transparent;
}

ul {
  list-style: none;
  width: min-content;
}

button {
  cursor: pointer;
}

body {
  background: rgba(12, 12, 12);
  color: white;
}

/* header START */
header {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.25) , rgba(255, 255, 255, 0.50));
  font-size: 36px;
  font-weight: bold;
  padding: 5px 0;
}
h1 {
  width: fit-content;
  margin: auto;
  color: rgb(187, 134, 252);
  font-family: "Arial Black", Gadget, sans-serif;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
}
/* header END */

/* nav-bar START */
h2 {
  font-size: 24px;
  text-align: center;
}

.nav-bar {
  padding: 10px 0;
  border-bottom: rgba(255, 255, 255, 0.16) 4px solid;
}

.controller {
  width: max-content;
  margin: auto;
  font-size: 24px;
  color: rgb(255, 117, 151);
}

.controller button {
  font-size: 16px;
  margin-right: 5px;
  border-radius: 5px;
  border: 2px rgb(255, 255, 255, 0.25) solid;
  padding: 2px 5px;
  background: rgba(255, 255, 255, 0.5);
}

.controller button:hover {
  background: rgba(255, 255, 255, 0.25);
  color: white;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 40px -10px rgba(12, 12, 12, 0.57);
  transition: all 0.4s ease 0s;
}

.nav-bar span {
  font-weight: bold;
}

.controller-sub {
  display: flex;
  flex-direction: row;
}

.controller-left > *,
.controller-right > * {
  padding: 5px;
  margin: 5px;
  border: 2px rgb(255, 255, 255, 0.12) solid;
  border-radius: 10px;
}

.build-board > * {
  margin: 2px 0;
}

.build-board dt {
  float: left;
  width: 85px;
}
.build-board dd {
  margin-left: 0;
}

.build-board input{
  margin-left: 1px;
  width: 50px;
  border-bottom: 1px rgb(255, 117, 151) solid;
}

.tile-option > * {
  margin: 2px 0;
}

.tile-option > div {
  display: flex;
  flex-direction: row;
}

.tile-option > div > div {
  width: 16px;
  height: 16px;
  margin: auto 5px;
  border: rgb(255, 255, 255, 0.75) 2px solid;
}

.tile-option > button > div {
  display: flex;
  flex-direction: row;
}

.tile-option > button > div > div {
  width: 14px;
  margin-left: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  border: rgb(255, 255, 255, 0.75) 2px solid;
}

.tile-option > div > p {
  min-width: 245px;
}

.maze-action > * {
  margin: 2px 0;
}

.maze-action div {
  display: flex;
  flex-direction: row;
}

.maze-action div > button {
  margin: 2px 5px;
  background: rgb(238, 144, 144, 0.75);
}

.maze-action .all-direction {
  background: rgb(144, 238, 144, 0.75);
}

.maze-action .checkbox {
  cursor: pointer;
  margin: 3.5px 5px;
  height: 20px;
  width: 20px;
  background-color: lightgray;
}

.maze-action select {
  cursor: pointer;
  margin: 1px 5px;
  height: 25px;
}

.maze-action dt {
  float: left;
  margin: 2.5px 0;
  width: 20px;
  height: 20px;
  border: lightgray 1px solid;
}

.maze-action dd {
  margin: auto 0;
}
/* nav-bar END */

/* maze START */
.maze {
  padding: 10px;
}

.board {
  margin: auto;
  border: rgb(255, 255, 255, 0.50) 2px solid;
  max-width: 100%;
  overflow-x: scroll;
}

.row {
  display: flex;
  flex-direction: row;
}

.tile {
  box-sizing: border-box;
  min-width: 50px;
  height: 50px;
  border: rgb(255, 255, 255, 0.50) 2px solid;
  cursor: pointer;
}

.blank { 
  background: black;
}

.wall {
  background: rgb(255, 255, 255, 0.75);
}

.possible {
  /* background: darkorange; */
}

.used {
  background: rgb(255, 255, 0, 0.75);
}

.solution {
  background: rgb(0, 128, 0, 0.75);
}

.start {
  background: rgb(144, 238, 144, 0.75);
}

.end {
  background: rgb(255, 182, 193, 0.75);
}
/* maze END */

/* CASE MAX-WIDTH < 600px */
@media (max-width: 600px) {
  .controller-sub {
    display: flex;
    flex-direction: column;
  }
}